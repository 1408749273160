import api from "../../../base/utils/request";
import download from "@/base/utils/download";

export const getIndex = data => {
    return api({
        url: '/admin/cyc/cyc_content/index',
        method: 'post',
        data
    })
}

export const getDelete = data => {
    return api({
        url: '/admin/cyc/cyc_content/del',
        method: 'post',
        data
    })
}

// 导出
export const orderDocument = data => {
    return download({
        url: "/admin/cyc/cyc_content/export",
        method: "post",
        data,
        download: true
    });
};

export const getDetail = data => {
    return api({
        url: '/admin/cyc/cyc_content/detail',
        method: 'post',
        data
    })
}

export const getSave = data => {
    return api({
        url: '/admin/cyc/cyc_content/save',
        method: 'post',
        data
    })
}
