<template>
  <LayoutFilter :onFilter="ok" :collapseHeight="120" :toggleCollapseEnable="false" :onExport="onExport"
    :onReset="resetForm">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="80px" size="medium"
      class="filter-from">
      <div class="filter-item">
        <DatePeriodPicker label="创建时间：" start-prop="start_time" :start-time.sync="form.start_time"
          end-prop="end_time" :end-time.sync="form.end_time" />
      </div>
      <div class="filter-item">
        <el-form-item label="页面名称：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入网页名称"></el-input>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";

import { orderDocument } from "../../api/cyc-content";

export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },

    filterForm: {
      type: Object,
    },
    // onExport:{
    //     type: Function,
    // }
  },
  data() {
    return {
      rules: {},
    };
  },

  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    onExport() {
      const postData = { ...this.form };
      return orderDocument(postData)
        .then((blob) => {})
        .catch((err) => {});
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() {},
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {
        this.$emit("updateFilter", val);
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>