<template>
  <div class="content">
    <div style="margin-bottom: 20px">
      <el-button size="small" @click="handleAddCompany" type="primary">新增</el-button>
    </div>
    <div>
      <SourceScreen :filterForm="filterForm" :uploadFilter="ok"></SourceScreen>
    </div>
    <div style="margin-top: 20px">
      <el-button class="top-btn" size="small" type="danger" @click="Remove">删除</el-button>
    </div>

    <div v-loading="loading" style="margin-top: 20px">
      <el-table :data="list" stripe @sort-change="sortChange" @selection-change="handleSelectionChange"
        class="thead-light">
        <el-table-column type="selection"></el-table-column>
        <el-table-column prop="id" min-width="100px" label="ID"></el-table-column>
        <el-table-column prop="name" min-width="110px" label="页面名称"></el-table-column>
        <el-table-column prop="update_time" min-width="150px" label="创建时间"></el-table-column>
        <el-table-column min-width="110" label="操作">
          <template slot-scope="scope">
            <el-button-group>
<!--              <el-button type="text" size="small" @click="scan(scope.row)">扫码预览-->
<!--              </el-button>-->
              <el-button type="text" size="small" @click="copy(scope.row.jump_link)">复制链接
              </el-button>
              <!--                            <el-button type="text" size="small" @click="preview(scope.row)">预览</el-button>-->
              <el-button type="text" size="small" @click="edit(scope.row.id)">编辑</el-button>
              <el-button type="text" size="small" @click="deleteRow(scope.row.id)">删除</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <two-code-dialog
            :show-code="openCodeDialog"
            :codes="codes"
            @closeCode="closeCodeDialog"
    ></two-code-dialog>
  </div>
</template>

<script>
import SourceScreen from "../../components/cyc-content/SourceScreen";
import { getIndex, getDelete } from "../../api/cyc-content";
import { copyText } from "@/base/utils/tool";
import pagePathMixin from '../../../app-design/mixins/pagePathMixin'
import TwoCodeDialog from '../../../app-design/components/Search/TwoCodeDialog'
export default {
  components: {
    TwoCodeDialog,
    SourceScreen,
  },
  mixins: [pagePathMixin],
  data() {
    return {
      filterForm: {
        order_by: "",
        start_time: -1,
        end_time: -1,
        is_desc: "",
        keyword: "",
      },
      list: [],
      selectArr: [],
      loading: false, //加载

      openCodeDialog: false,
      codes: []
    };
  },
  created() {
    this.updataIndex();
  },
  methods: {
    closeCodeDialog() {
      this.codes = []
      this.openCodeDialog = false
    },
    showThreeCodes(r) {
      this.openCodeDialog = true
      const row = {
        mp_link: r.jump_link,
        jump_link: {
          jump_type: 'page',
          jump_config: {
            // path: `/sub/single/pages/index?id=${r.id}&name=${encodeURIComponent(r.name)}`,
            path: `/sub/single/pages/index?id=${r.id}`,
          }
        }
      }
      Promise.all([this.showCode(row, 'trial'), this.showCode(row, 'release'), this.showCode(row, 'h5')])
          .then((res) => {
            this.codes = res
          })
          .catch((err) => {
            console.log(err)
          })
    },
    scan(row) {
      this.showThreeCodes(row)
    },
    // 点击列表删除
    deleteRow(id) {
      this.$msgbox
        .confirm("确定要将此数据删除吗？", "提示", {
          type: "info",
        })
        .then((res) => {
          this.loading = true;
          getDelete({ id: id })
            .then((res) => {
              this.$message.success(res.msg);
              this.updataIndex();
            })
            .catch((err) => {
              this.loading = false;
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    updataIndex() {
      this.loading = true;
      getIndex(this.filterForm)
        .then((res) => {
          this.list = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    Remove() {
      if (!this.selectArr.length) {
        this.$message({
          type: "success",
          message: "请选择数据",
        });
        return;
      }
      if (this.selectArr.length) {
        this.$msgbox
          .confirm("确定要将这些数据删除吗？", "提示", {
            type: "warning",
          })
          .then((res) => {
            this.loading = true;
            getDelete({ id: this.selectArr })
              .then((res) => {
                this.$message.success(res.msg);
                this.updataIndex();
              })
              .catch((err) => {
                this.loading = false;
              });
          })
          .catch((err) => {
            this.updataIndex();
          });
      }
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id);
        });
      }
    },
    ok(e) {
      this.filterForm = {...e, page: 1};
      this.updataIndex();
    },
    sortChange() {},
    // 复制
    copy(refName) {
      copyText(refName).then((valid) => {
        this.$message.success("复制成功");
      });
    },
    handleAddCompany() {
      this.$router.push({
        name: "AddContent",
        params: { type: "add", id: 0 },
      });
    },
    edit(id) {
      console.log(id);
      this.$router.push({
        name: "EditContent",
        params: { type: "add", id: id },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.content {
}
</style>
